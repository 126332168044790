import React, { useState, useEffect, Fragment, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Theme from "../../themes/Theme";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { BasicStatusButton } from "../aggrid/dynamicAction";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: Theme.tableStyle.tableHeaders.fontWeightImportant,
  },
}));

const BasicTable = ({
  dataTable,
  dataColumns,
  action,
  condition = [],
  align = "center",
  dynamicAction = [],
  rowsPerPageOpt = [5, 10, 25, 50],
  tableType = "",
  pageValue = 0,
  rowsPerPageValue = 10,
  columnsSort = ["amount", "store_name", "date"],
  isTxtRowsPerPagHidden = true,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(pageValue);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageValue);
  const [t] = useTranslation("global");
  const [rowData, setRowData] = useState(dataTable);

  //sort columns
  const ascending = "asc";
  const descending = "desc";

  const initArrowColumns = () => {
    let arrow = {};

    if (
      columnsSort !== undefined &&
      columnsSort !== null &&
      columnsSort.length > 0
    ) {
      columnsSort.forEach((column) => {
        arrow[column] = ascending;
      });
    }

    return arrow;
  };

  const [arrowColumns, setArrowColumns] = useState(initArrowColumns);

  useEffect(() => {
    setRowData(dataTable);
  }, [dataTable]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    if (tableType === "Micromerchant") {
      localStorage.setItem(tableType + "-page", parseInt(newPage));
    }
  };

  const storageRowsPerPage = useCallback(
    (event) => {
      setPage(0);
      if (tableType === "Micromerchant") {
        localStorage.setItem(tableType + "-rowsPerPage", parseInt(event));
      }
    },
    [tableType]
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    storageRowsPerPage(event.target.value);
  };

  const handleChangeRowsPerPageText = useCallback(
    (event) => {
      if (event.target.value === "") {
        setRowsPerPage(0);
      } else {
        if (parseInt(event.target.value) < 1) {
          event.target.value = 1;
        }
        setRowsPerPage(parseInt(event.target.value, 10));
      }
      storageRowsPerPage(event.target.value);
    },
    [storageRowsPerPage]
  );

  const sortColumn = (fieldName, fun = String) => {
    return rowData.sort(
      sortBy(fieldName, arrowColumns[fieldName] === descending, fun)
    );
  };

  const sortBy = (fieldName, reverse, fun) => {
    const key = fun
      ? function (x) {
          return fun(x[fieldName]);
        }
      : function (x) {
          return x[fieldName];
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      a = key(a);
      b = key(b);
      return reverse * ((a > b) - (b > a));
    };
  };

  const numberParse = (text) => {
    return Number(text.replace(/[^0-9.]+/, ""));
  };

  const handleSortRequest = (col) => {
    const fieldName = col.split(".").pop();

    switch (fieldName) {
      case "amount":
        setRowData(sortColumn(fieldName, numberParse));
        break;
      case "date":
      case "application_date":
        setRowData(sortColumn(fieldName, Date.parse));
        break;
      case "level":
        setRowData(sortColumn(fieldName, parseInt));
        break;
      default:
        setRowData(sortColumn(fieldName));
        break;
    }

    directionArrow(fieldName);
  };

  const directionArrow = (fieldName) => {
    arrowColumns[fieldName] =
      arrowColumns[fieldName] === ascending ? descending : ascending;
    setArrowColumns({ ...arrowColumns });
  };

  if (Object.keys(dataTable).length === 0)
    return (
      <div className="no-information-message">{t("Users.Delete.NoUsers")}</div>
    );

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const getOrder = (fieldName) => {
    return arrowColumns[fieldName];
  };

  const getTableCell = (column, idx) => {
    const fieldName = column.split(".").pop();

    return (
      <TableCell key={idx} align={align} className={classes.header}>
        {columnsSort.includes(fieldName) ? (
          <TableSortLabel
            active={true}
            direction={getOrder(fieldName)}
            onClick={() => handleSortRequest(column)}
          >
            {t(column)}
          </TableSortLabel>
        ) : (
          t(column)
        )}
      </TableCell>
    );
  };

  const valueDesign = (result, cellData) => {
    if (result.icon === "MicromerchantsFormStatus") {
      return (
        <BasicStatusButton
          title="Micromerchants.LevelChange.LblStatusForm"
          color="green"
          icon={<CheckIcon />}
        />
      );
    } else {
      if (cellData) {
        return (
          <BasicStatusButton
            title="Micromerchants.LevelChange.LblStatusUser.Valid"
            color="green"
            icon={<CheckIcon />}
          />
        );
      } else {
        return (
          <BasicStatusButton
            title="Micromerchants.LevelChange.LblStatusUser.Pending"
            color="yellow"
            icon={<AccessTimeIcon />}
          />
        );
      }
    }
  };

  const cellValue = (rowData, cell, existDynamicAction) =>
    existDynamicAction
      ? valueDesign(dynamicAction.filter((x) => x.field === cell)[0], rowData)
      : rowData;

  function getCells(row, i) {
    return Object.keys(row).map((cell, index) => {
      if (index + 1 === Object.keys(row).length) {
        return setCell(row, i, cell);
      } else {
        if (!condition.includes(cell)) {
          return (
            <TableCell
              key={uuidv4()}
              align={align}
              style={Theme.tableStyle.tableBody}
            >
              {cellValue(
                row[cell],
                cell,
                dynamicAction.some((item) => item.field === cell)
              )}
            </TableCell>
          );
        }
      }
    });
  }

  function setCell(row, i, cell) {
    if (condition.includes(cell)) {
      return action !== undefined && action(row, i);
    } else {
      return (
        <Fragment key={uuidv4()}>
          <TableCell
            key={uuidv4()}
            align={align}
            style={Theme.tableStyle.tableBody}
          >
            {row[cell]}
          </TableCell>
          {action !== undefined && action(row, i)}
        </Fragment>
      );
    }
  }

  return (
    <div>
      <div>
        <Paper
          sx={{
            boxShadow: "0px 4px 11px rgba(194, 209, 217, 0.46)",
            borderRadius: "16px",
          }}
        >
          <TableContainer role="grid">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {dataColumns.map((col, idx) =>
                    col.includes("Action") ? (
                      <TableCell
                        key={idx}
                        align="center"
                        colSpan="2"
                        className={classes.header}
                      >
                        {t(col)}
                      </TableCell>
                    ) : (
                      getTableCell(col, idx)
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <StyledTableRow key={uuidv4()}>
                        {getCells(row, i)}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              marginTop: "1%",
            }}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              hidden={isTxtRowsPerPagHidden}
              onChange={handleChangeRowsPerPageText}
              type="number"
              label={t("Insights.Transactions.Table.LabelRowsPerPage")}
              defaultValue={rowsPerPageValue}
              sx={{
                width: "110px",
              }}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOpt}
              component="div"
              count={dataTable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t(
                "Insights.Transactions.Table.LabelRowsPerPage"
              )}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default BasicTable;
